import * as React from "react";
import Layout from "../components/Layout/Layout";
import Grid from '@mui/material/Grid';
import { Box } from "@mui/material";
import { ContactForm } from "../components/ContactForm/ContactForm";
import { getRichContent } from "../components/CardBasic/CardBasic";

const AboutBasicUsage = (props: { pageContext: any; }) => {
    const { pageContext } = props
  
    var siteJson = pageContext.site;
    if (siteJson == null) {
      siteJson = new Object();
    }
  
    var tenantJson = pageContext.tenant;
    if (tenantJson == null) {
      tenantJson = new Object();
    }

    let tenantAboutDescriptionHtml = "";

    if (tenantJson.tenant_about_description_html != null) {
        tenantAboutDescriptionHtml = tenantJson.tenant_about_description_html;
    }
  
    return (

        <Layout
            dynamicMenuItems={[]}
            pageTitle={'About'}
            siteHeaderLogoImageUrl={siteJson.site_header_logo_image_url}
            siteTitle={siteJson.site_title}
            tenantId={tenantJson.tenant_id}
        >
          {/*  <Typography variant="h4">About</Typography>   */}
            <br></br>
            <Grid container spacing={3} alignItems="stretch">

                <Grid item xs={12} sm={6}>
                    <Grid container alignItems="center" direction="column">
                        <Box width={'300px'}>
                              {getRichContent(tenantAboutDescriptionHtml)}
                        </Box>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Grid container alignItems="flex-start" direction="column">
                        <Box width={'300px'}>
                           <ContactForm tenant={tenantJson}></ContactForm>
                        </Box>
                    </Grid>
                </Grid>

            </Grid>

        </Layout>
    )
}
export default AboutBasicUsage